<template>
  <form class="vehicle-form-inner" ref="form" @submit.prevent="submit">
    <div class="inspection-form-group width-1_2">
      <base-input
        :label="`${$t('VEHICLES.CONDITION')}`"
        :placeholder="`${$t('VEHICLES.CONDITION')}`"
        v-model="vehicle.condition"
        :disable="disable"
        :required="true"
      >
        <condition-selector
          :disabled="disable"
          :filterable="true"
          :value="vehicle.condition"
          @conditionChanged="
            (condition) => {
              vehicle.condition = condition;
            }
          "
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.condition" />
    </div>

    <div class="inspection-form-group width-1_4">
      <div class="checkbox-wrapper">
        <base-checkbox
          v-model="vehicle.ownership_can_be_transferred"
          :label="`${$t('VEHICLES.OWNERSHIP_CAN_BE_TRANSFERRED')}`"
          :disabled="disable"
          @change="
            handleCheckboxChange(
              vehicle.ownership_can_be_transferred,
              'ownership_can_be_transferred'
            )
          "
        >
          <span>{{ $t("VEHICLES.OWNERSHIP_CAN_BE_TRANSFERRED") }}</span>
        </base-checkbox>
      </div>
      <validation-error
        :errors="apiValidationErrors.ownership_can_be_transferred"
      />
    </div>

    <div class="inspection-form-group width-1_4">
      <div class="checkbox-wrapper">
        <base-checkbox
          v-model="vehicle.must_be_towed"
          :label="`${$t('VEHICLES.MUST_BE_TOWED')}`"
          :placeholder="`${$t('VEHICLES.MUST_BE_TOWED')}`"
          :disable="disable"
          :required="true"
          @change="handleCheckboxChange(vehicle.must_be_towed, 'must_be_towed')"
        >
          <span>{{ $t("VEHICLES.MUST_BE_TOWED") }}</span>
        </base-checkbox>
      </div>
      <validation-error :errors="apiValidationErrors.must_be_towed" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        v-model="vehicle.carfax_reclaims"
        :label="`${$t('VEHICLES.CARFAX_RECLAIMS')}`"
        :disable="disable"
        :required="vehicle.carfax_reporting_enabled == true"
        :placeholder="`${$t('VEHICLES.CARFAX_RECLAIMS')}`"
      />

      <validation-error :errors="apiValidationErrors.carfax_reclaims" />
    </div>

    <div class="inspection-form-group width-1_4">
      <label>{{ $t("VEHICLES.CARFAX_REPORTING_ENABLED") }}</label>

      <div class="custom-radio-wrapper">
        <base-radio
          v-model="vehicle.carfax_reporting_enabled"
          name="carfax_reporting_enabled"
          :radio-value="true"
          :disable="disable"
          :required="true"
          :placeholder="`${$t('VEHICLES.CARFAX_REPORTING_ENABLED')}`"
          @input="
            (value) => {
              vehicle.carfax_reporting_enabled = value;
            }
          "
        >
          {{ $t("COMMON.YES") }}
        </base-radio>

        <base-radio
          v-model="vehicle.carfax_reporting_enabled"
          name="carfax_reporting_enabled"
          :radio-value="false"
          :disable="disable"
          :required="true"
          :placeholder="`${$t('VEHICLES.CARFAX_REPORTING_ENABLED')}`"
          @input="
            (value) => {
              vehicle.carfax_reporting_enabled = value;
            }
          "
        >
          {{ $t("COMMON.NO") }}
        </base-radio>
      </div>

      <validation-error
        :errors="apiValidationErrors.carfax_reporting_enabled"
      />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.CARFAX_API')}`"
        :placeholder="`${$t('VEHICLES.CARFAX_API')}`"
        v-model="vehicle.carfax_api"
        :disable="disable"
        :required="vehicle.carfax_reporting_enabled == true"
      />

      <validation-error :errors="apiValidationErrors.carfax_api" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        v-model="vehicle.tire_condition"
        :label="`${$t('VEHICLES.TIRE_CONDITION')}`"
        :placeholder="`${$t('VEHICLES.TIRE_CONDITION')}`"
        :disable="disable"
        :required="true"
      >
        <tire-condition-selector
          :disabled="disable"
          :filterable="true"
          :value="vehicle.tire_condition"
          @tireConditionChanged="
            (tireCondition) => {
              vehicle.tire_condition = tireCondition;
            }
          "
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.tire_condition" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        v-model="vehicle.tpms"
        type="number"
        :label="`${$t('VEHICLES.TPMS')}`"
        :placeholder="`${$t('VEHICLES.TPMS')}`"
        :disable="disable"
        :required="true"
      />

      <validation-error :errors="apiValidationErrors.tpms" />
    </div>

    <div class="inspection-form-group width-1_4">
      <label>{{ $t("VEHICLES.HAS_ORIGINAL_WHEELS") }}</label>
      <div class="custom-radio-wrapper">
        <base-radio
          v-model="vehicle.has_original_wheels"
          name="has_original_wheels"
          :radio-value="true"
          :disable="disable"
          :required="true"
          :placeholder="`${$t('VEHICLES.HAS_ORIGINAL_WHEELS')}`"
          @input="
            (value) => {
              vehicle.has_original_wheels = value;
            }
          "
        >
          {{ $t("COMMON.YES") }}
        </base-radio>

        <base-radio
          v-model="vehicle.has_original_wheels"
          name="has_original_wheels"
          :radio-value="false"
          :disable="disable"
          :required="true"
          :placeholder="`${$t('VEHICLES.HAS_ORIGINAL_WHEELS')}`"
          @input="
            (value) => {
              vehicle.has_original_wheels = value;
            }
          "
        >
          {{ $t("COMMON.NO") }}
        </base-radio>
      </div>

      <validation-error :errors="apiValidationErrors.has_original_wheels" />
    </div>

    <div class="inspection-form-group width-1_4">
      <label>{{ $t("VEHICLES.HAS_WINTER_TIRES") }}</label>
      <div class="custom-radio-wrapper">
        <base-radio
          v-model="vehicle.has_winter_tires"
          name="has_winter_tires"
          :radio-value="true"
          :disable="disable"
          :required="true"
          :placeholder="`${$t('VEHICLES.HAS_WINTER_TIRES')}`"
          @input="
            (value) => {
              vehicle.has_winter_tires = value;
            }
          "
        >
          {{ $t("COMMON.YES") }}
        </base-radio>

        <base-radio
          v-model="vehicle.has_winter_tires"
          name="has_winter_tires"
          :radio-value="false"
          :disable="disable"
          :required="true"
          :placeholder="`${$t('VEHICLES.HAS_WINTER_TIRES')}`"
          @input="
            (value) => {
              vehicle.has_winter_tires = value;
            }
          "
        >
          {{ $t("COMMON.NO") }}
        </base-radio>
      </div>

      <validation-error :errors="apiValidationErrors.has_winter_tires" />
    </div>

    <div class="inspection-form-group width-1_4">
      <label>{{ $t("VEHICLES.HAS_STUDDED_WINTER_TIRES") }}</label>
      <div class="custom-radio-wrapper">
        <base-radio
          v-model="vehicle.has_studded_winter_tires"
          name="has_studded_winter_tires"
          :radio-value="true"
          :disable="disable"
          :required="true"
          :placeholder="`${$t('VEHICLES.HAS_STUDDED_WINTER_TIRES')}`"
          @input="
            (value) => {
              vehicle.has_studded_winter_tires = value;
            }
          "
        >
          {{ $t("COMMON.YES") }}
        </base-radio>

        <base-radio
          v-model="vehicle.has_studded_winter_tires"
          name="has_studded_winter_tires"
          :radio-value="false"
          :disable="disable"
          :required="true"
          :placeholder="`${$t('VEHICLES.HAS_STUDDED_WINTER_TIRES')}`"
          @input="
            (value) => {
              vehicle.has_studded_winter_tires = value;
            }
          "
        >
          {{ $t("COMMON.NO") }}
        </base-radio>
      </div>

      <validation-error
        :errors="apiValidationErrors.has_studded_winter_tires"
      />
    </div>

    <div class="inspection-form-group width-1_4">
      <label>{{ $t("VEHICLES.HAS_FOUR_IDENTICAL_TIRES") }}</label>
      <div class="custom-radio-wrapper">
        <base-radio
          v-model="vehicle.has_four_identical_tires"
          name="has_four_identical_tires"
          :radio-value="true"
          :disable="disable"
          :required="true"
          :placeholder="`${$t('VEHICLES.HAS_FOUR_IDENTICAL_TIRES')}`"
          @input="
            (value) => {
              vehicle.has_four_identical_tires = value;
            }
          "
        >
          {{ $t("COMMON.YES") }}
        </base-radio>

        <base-radio
          v-model="vehicle.has_four_identical_tires"
          name="has_four_identical_tires"
          :radio-value="false"
          :disable="disable"
          :required="true"
          :placeholder="`${$t('VEHICLES.HAS_FOUR_IDENTICAL_TIRES')}`"
          @input="
            (value) => {
              vehicle.has_four_identical_tires = value;
            }
          "
        >
          {{ $t("COMMON.NO") }}
        </base-radio>
      </div>

      <validation-error
        :errors="apiValidationErrors.has_four_identical_tires"
      />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        v-model="vehicle.second_tires"
        :label="`${$t('VEHICLES.SECOND_TIRES')}`"
        :placeholder="`${$t('VEHICLES.SECOND_TIRES')}`"
        :disable="disable"
        :required="true"
      >
        <second-tires-selector
          :disabled="disable"
          :filterable="true"
          :value="vehicle.second_tires"
          @secondTiresChanged="
            (secondTires) => {
              vehicle.second_tires = secondTires;
            }
          "
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.second_tires" />
    </div>

    <div class="inspection-form-group width-1_4">
      <label>{{ $t("VEHICLES.ACCIDENT_INVOLVED") }}</label>
      <div class="custom-radio-wrapper">
        <base-radio
          v-model="vehicle.accident_involved"
          name="accident_involved"
          :radio-value="true"
          :disable="disable"
          :required="true"
          :placeholder="`${$t('VEHICLES.ACCIDENT_INVOLVED')}`"
          @input="
            (value) => {
              vehicle.accident_involved = value;
            }
          "
        >
          {{ $t("COMMON.YES") }}
        </base-radio>

        <base-radio
          v-model="vehicle.accident_involved"
          name="accident_involved"
          :radio-value="false"
          :disable="disable"
          :required="true"
          :placeholder="`${$t('VEHICLES.ACCIDENT_INVOLVED')}`"
          @input="
            (value) => {
              vehicle.accident_involved = value;
            }
          "
        >
          {{ $t("COMMON.NO") }}
        </base-radio>
      </div>

      <validation-error :errors="apiValidationErrors.accident_involved" />
    </div>

    <div class="inspection-form-group width-1">
      <base-input
        :label="`${$t('VEHICLES.DECLARATIONS')}`"
        :placeholder="`${$t('VEHICLES.DECLARATIONS')}`"
        :disable="disable"
        :required="true"
      >
        <declarations-selector
          :disabled="disable"
          :filterable="true"
          :value="vehicle.declarations ?? []"
          @declarationsChanged="
            (declarations) => {
              vehicle.declarations = declarations;
            }
          "
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.declarations" />
    </div>

    <div class="inspection-form-group width-1">
      <base-input
        v-model="vehicle.seller_notes"
        :label="`${$t('VEHICLES.SELLER_NOTES')}`"
        :placeholder="`${$t('VEHICLES.SELLER_NOTES')}`"
        :disable="disable"
      >
        <textarea
          class="form-control"
          v-model="vehicle.seller_notes"
          rows="5"
        ></textarea>
      </base-input>

      <validation-error :errors="apiValidationErrors.seller_notes" />
    </div>
  </form>
</template>

<script>
import _, { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseCheckbox from "@/components/Inputs/BaseCheckbox.vue";
import ConditionSelector from "@/components/ConditionSelector.vue";
import TireConditionSelector from "@/components/TireConditionSelector.vue";
import SecondTiresSelector from "@/components/SecondTiresSelector.vue";
import DeclarationsSelector from "@/components/DeclarationsSelector.vue";

export default {
  name: "step2",
  components: {
    BaseInput,
    BaseCheckbox,
    ValidationError,
    ConditionSelector,
    TireConditionSelector,
    SecondTiresSelector,
    DeclarationsSelector,
  },

  mixins: [formMixin],

  props: {
    vehicleData: { type: Object },
    formErrors: {
      type: Array,
      default: () => [],
    },
    loading: { type: Boolean, default: false },
    disable: { type: Boolean, default: false },
  },

  data() {
    let vehicleData = cloneDeep(this.vehicleData);

    return {
      vehicle: vehicleData,
    };
  },

  computed: {},

  created() {},

  methods: {
    handleCheckboxChange(value, attrib) {
      const isChecked = value === "true";

      this.vehicle[attrib] = !isChecked;
    },
    isFormValid() {
      return this.$refs.form.checkValidity();
    },
    submit() {
      this.$emit("submitted", {
        ...cloneDeep(this.vehicle),
        current_step: 1,
      });
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    vehicleData(vehicleData) {
      console.log("watch vehicle data", vehicleData);

      if (vehicleData) {
        this.vehicle = cloneDeep(vehicleData);
      }
    },
    vehicle: {
      handler: function (value) {
        if (_.isEqual(value, this.vehicle)) return;

        this.$emit("formChanged", this.vehicle);
      },
      deep: true,
    },
  },
};
</script>
