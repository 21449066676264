<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3>{{ $objectDenomination(vehicle) }}</h3>
        </div>

        <div class="col-6 text-right">
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_DELETE_VEHICLES)"
            @click="deleteVehicle"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_EDIT_OWN_VEHICLES)"
            @click="editVehicle"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <img
        v-if="vehicle.exterior_photos?.length > 0"
        class="all-infos-image"
        :src="vehicle.exterior_photos[0]"
        alt="logo"
      />

      <img v-else class="all-infos-image" src="/img/camera.svg" alt="logo" />

      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("VEHICLES.VIN") }}</dt>
          <dd>
            {{ vehicle.vin }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.SKU") }}</dt>
          <dd>
            {{ vehicle.sku }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.MODEL_YEAR") }}</dt>
          <dd>
            {{ vehicle.model_year }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.MAKE") }}</dt>
          <dd>
            {{ vehicle.make }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.MODEL") }}</dt>
          <dd>
            {{ vehicle.model }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.SERIES") }}</dt>
          <dd>
            {{ vehicle.series }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.STYLE") }}</dt>
          <dd>
            {{ vehicle.style }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.OUTSIDE_COLOR") }}</dt>
          <dd>
            {{
              vehicle.outside_color
                ? $t(`VEHICLES.COLOR_${vehicle.outside_color}`)
                : "-"
            }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.INSIDE_COLOR") }}</dt>
          <dd>
            {{
              vehicle.inside_color
                ? $t(`VEHICLES.COLOR_${vehicle.inside_color}`)
                : "-"
            }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.ENGINE_DESCRIPTION") }}</dt>
          <dd>
            {{ vehicle.engine_description }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.GEARBOX") }}</dt>
          <dd>
            {{ $t(`TRANSMISSIONS.${vehicle.transmission}`) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.FUEL_TYPE") }}</dt>
          <dd>
            {{
              vehicle.fuel_type
                ? $t(`FUEL_TYPES.${vehicle.fuel_type.toUpperCase()}`)
                : "-"
            }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.ODOMETER") }}</dt>
          <dd>{{ vehicle.odometer_mileage }} {{ vehicle.odometer_type }}</dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.CAR_TYPE") }}</dt>
          <dd>
            {{
              vehicle.car_type
                ? $t(`VEHICLES.CAR_TYPE_${vehicle.car_type}`)
                : "-"
            }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.TRANSMISSION") }}</dt>
          <dd>
            {{ vehicle.drivetrain }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.NB_PASSENGERS") }}</dt>
          <dd>
            {{ vehicle.nb_passengers }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.NB_DOORS") }}</dt>
          <dd>
            {{ vehicle.nb_doors }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.ACCESSORIES") }}</dt>
          <dd>
            <ul>
              <li v-for="(a, i) in vehicle.accessories" :key="i">
                {{ a }}
              </li>
            </ul>
          </dd>
        </dl>

        <div class="row">
          <dt>{{ $t("VEHICLES.CONDITION") }}</dt>
          <dd>
            {{ $t("VEHICLES.CONDITION_" + vehicle.condition) }}
          </dd>
        </div>

        <div class="row">
          <dt>
            {{ $t("VEHICLES.OWNERSHIP_CAN_BE_TRANSFERRED") }}
          </dt>
          <dd>
            {{
              vehicle.ownership_can_be_transferred
                ? $t("COMMON.YES")
                : $t("COMMON.NO")
            }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.MUST_BE_TOWED") }}</dt>
          <dd>
            {{ vehicle.must_be_towed ? $t("COMMON.YES") : $t("COMMON.NO") }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.CARFAX_RECLAIMS") }}</dt>
          <dd>
            {{ vehicle.carfax_reclaims }}
          </dd>
        </div>

        <div class="row">
          <dt>
            {{ $t("VEHICLES.CARFAX_REPORTING_ENABLED") }}
          </dt>
          <dd>
            {{
              vehicle.carfax_reporting_enabled
                ? $t("COMMON.YES")
                : $t("COMMON.NO")
            }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.CARFAX_API") }}</dt>
          <dd>
            {{ vehicle.carfax_api }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.TIRE_CONDITION") }}</dt>
          <dd>
            {{ $t("VEHICLES.CONDITION_" + vehicle.tire_condition) }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.TPMS") }}</dt>
          <dd>
            {{ vehicle.tpms }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.HAS_ORIGINAL_WHEELS") }}</dt>
          <dd>
            {{
              vehicle.has_original_wheels ? $t("COMMON.YES") : $t("COMMON.NO")
            }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.HAS_WINTER_TIRES") }}</dt>
          <dd>
            {{ vehicle.has_winter_tires ? $t("COMMON.YES") : $t("COMMON.NO") }}
          </dd>
        </div>

        <div class="row">
          <dt>
            {{ $t("VEHICLES.HAS_STUDDED_WINTER_TIRES") }}
          </dt>
          <dd>
            {{
              vehicle.has_studded_winter_tires
                ? $t("COMMON.YES")
                : $t("COMMON.NO")
            }}
          </dd>
        </div>

        <div class="row">
          <dt>
            {{ $t("VEHICLES.HAS_FOUR_IDENTICAL_TIRES") }}
          </dt>
          <dd>
            {{
              vehicle.has_four_identical_tires
                ? $t("COMMON.YES")
                : $t("COMMON.NO")
            }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.SECOND_TIRES") }}</dt>
          <dd>
            {{ vehicle.second_tires }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.DECLARATIONS") }}</dt>
          <dd>
            <ul>
              <li v-for="(a, i) in vehicle.declarations" :key="i">
                {{ a }}
              </li>
            </ul>
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.SELLER_NOTES") }}</dt>
          <dd>
            {{ vehicle.seller_notes }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.DEALER_NAME") }}</dt>
          <dd>
            {{ vehicle.location?.name ?? vehicle.organization?.name ?? "" }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.AUCTION_DATE") }}</dt>
          <dd>
            {{
              vehicle.auction_date
                ? $formatDate(vehicle.auction_date, "LL")
                : ""
            }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.PICKUP_LOCATION") }}</dt>
          <dd>
            {{ vehicle.pickup_location }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.BUY_NOW_PRICE") }}</dt>
          <dd>
            {{ $formatCurrency(vehicle.buy_now_price) }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.MIN_BID_AMOUNT") }}</dt>
          <dd>
            {{ $formatCurrency(vehicle.min_bid_amount) }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.RESERVE_PRICE") }}</dt>
          <dd>
            {{ $formatCurrency(vehicle.reserve_price) }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("COMMON.STATUS") }}</dt>
          <dd>
            <badge
              :type="
                vehicle.status.toLowerCase() ===
                VEHICLE_STATUS_COMPLETED.toLowerCase()
                  ? 'success'
                  : 'warning'
              "
            >
              {{ $t(`COMMON.${vehicle.status.toUpperCase() ?? "DRAFT"}`) }}
            </badge>
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("COMMON.VALIDATED") }}</dt>
          <dd>
            <badge :type="vehicle.is_validated ? 'success' : 'warning'">
              {{ vehicle.is_validated ? $t("COMMON.YES") : $t("COMMON.NO") }}
            </badge>
          </dd>
        </div>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <organization
              v-if="vehicle.organization"
              :organization="vehicle.organization"
            />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd v-if="vehicle.created_at">
            {{ $timeZoneDateFormat(vehicle.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd v-if="vehicle.updated_at">
            {{ $timeZoneDateFormat(vehicle.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { VEHICLE_STATUS_COMPLETED } from "@/constants/vehicles";

export default {
  name: "vehicle-view-global",

  components: {},

  props: ["vehicle"],

  data() {
    return {
      VEHICLE_STATUS_COMPLETED: VEHICLE_STATUS_COMPLETED,
    };
  },

  computed: {},

  created() {},

  methods: {
    editVehicle() {
      this.$emit("onEditVehicle", this.vehicle);
    },

    deleteVehicle() {
      this.$emit("onDeleteVehicle", this.vehicle);
    },
  },

  mounted() {},

  watch: {
    vehicle(vehicle) {},
  },
};
</script>
